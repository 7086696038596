import Layout from "@components/Layout";
import Anchor from "@components/Util/Anchor";
import SocialIcons from "@components/SocialIcons";

const NotFound = ({ title, description, ...props }) => {
  return (
    <>
      <Layout pageTitle={title} description={description}>
        <div className="max-w-screen-xl m-auto p-8">
          <main>
            <div className="max-w-screen-xl m-auto p-8">
              <h1 className="text-4xl font-bold line leading-10 mb-4">404</h1>
              Hey I'm John Okely. Not sure where you were headed so feel free to
              let me know that you've found a broken link. Or head{" "}
              <Anchor href="/">
                <span className="text-blue-500 hover:text-blue-600">
                  to the homepage
                </span>
              </Anchor>
              .
              <div className="text-center">
                <div className="m-auto mt-8">
                  <SocialIcons />
                </div>
              </div>
            </div>
          </main>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;

export async function getStaticProps() {
  const configData = await import(`../siteconfig.json`);

  return {
    props: {
      title: `Page not found | ${configData.default.title}`,
      description: configData.default.description,
    },
  };
}
